<template lang="pug">
  svg#iug_fullScreen(width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M13.438 0.295C13.256 0.113 13.006 0 12.729 0H8.618C8.063 0 7.616 0.447 7.616 1C7.616 1.554 8.063 2 8.618 2H10.321L9.139 3.182C8.728 2.953 8.262 2.813 7.759 2.813H2.865C1.285 2.813 0 4.097 0 5.676V10.572C0 12.152 1.285 13.437 2.865 13.437H7.76C9.34 13.437 10.625 12.152 10.625 10.572V5.676C10.625 5.334 10.555 5.01 10.444 4.705L11.735 3.414V5.117C11.735 5.672 12.182 6.119 12.735 6.118C13.288 6.118 13.735 5.671 13.735 5.117V1.006C13.735 0.729 13.622 0.477 13.438 0.295ZM8.625 10.573C8.625 11.051 8.237 11.438 7.76 11.438H2.865C2.387 11.438 2 11.05 2 10.573V5.676C2 5.199 2.388 4.813 2.865 4.813H7.51L5.606 6.715C5.215 7.106 5.213 7.738 5.606 8.129C5.997 8.522 6.629 8.52 7.02 8.129L8.624 6.525V10.573H8.625Z')
</template>

<script>
export default {
  name: 'IUG_FullScreenSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#667b87',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>